import React, { Fragment, Suspense, lazy } from "react";
import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from "@mui/material";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import Pace from "./shared/components/Pace";
import { Amplify } from "aws-amplify";
import awsExports, {
  authComponets,
  authServices,
  authTheme,
  formFields,
} from "./shared/functions/aws-exports";
import {
  Authenticator,
  ThemeProvider as AuthThemeProvider,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Provider } from "react-redux";
import configureStore from "./config/configureStore";

const LoggedInComponent = lazy(() => import("./dashboard/components/Main"));
const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));

// Configure Amplify in index file or root file
Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

const store = configureStore();

function App() {
  const [hideContent, setHideContent] = React.useState(false);
  React.useEffect(() => {
    // TEMPORARY REDIRECT
    const redirectTo = process.env.REACT_APP_REDIRECT_URL;
    if (
      redirectTo &&
      redirectTo !== "" &&
      !(
        window.location.pathname.startsWith("/dashboard") ||
        window.location.hash === "#test"
      )
    ) {
      setHideContent(true);
      window.location.href = redirectTo;
    }
  }, [setHideContent]);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            {/* <Elements stripe={stripe} options={{}}> */}
            <CssBaseline />
            <GlobalStyles />
            <Pace color={theme.palette.primary.light} />
            <Suspense fallback={<Fragment />}>
              <Switch>
                <Route path="/dashboard">
                  <AuthThemeProvider theme={authTheme(theme)}>
                    <Authenticator
                      hideSignUp={true}
                      components={authComponets}
                      formFields={formFields}
                      services={authServices}
                    >
                      {({ signOut, user }) => (
                        <LoggedInComponent signOut={signOut} user={user} />
                      )}
                    </Authenticator>
                  </AuthThemeProvider>
                </Route>
                <Route>{hideContent ? "" : <LoggedOutComponent />}</Route>
              </Switch>
            </Suspense>
            {/* </Elements> */}
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
