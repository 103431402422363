import {
  Button,
  Heading,
  Image,
  Link,
  Text,
  View,
  useAuthenticator,
  useTheme,
} from "@aws-amplify/ui-react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  REGION: process.env.REACT_APP_REGION,
  USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
  USER_POOL_APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_APP_CLIENT_ID,
};

// https://ui.docs.amplify.aws/react/connected-components/authenticator/customization
export const authComponets = {
  Header() {
    const { tokens } = useTheme();

    return (
      <>
        <View
          textAlign="center"
          padding={tokens.space.large}
          style={{ marginTop: "1rem" }}
        >
          <Link href="/">
            <Image
              src={`${process.env.PUBLIC_URL}/images/mdlcodelogo.png`}
              alt="MDLCode"
            />
          </Link>
        </View>
        <div style={{ paddingBottom: 8, paddingLeft: "2rem" }}>
          <Link href="/" style={{ display: "flex" }}>
            <KeyboardBackspaceIcon />
            <div style={{ paddingLeft: 3 }}>Return to MDLCode</div>
          </Link>
        </div>{" "}
      </>
    );
  },

  // Footer() {
  //   const { tokens } = useTheme();

  //   return (
  //     <View textAlign="center" padding={tokens.space.large}>
  //       <Text color={tokens.colors.neutral[80]}>
  //         &copy; All Rights Reserved
  //       </Text>
  //     </View>
  //   );
  // },

  SignIn: {
    Header() {
      const { tokens } = useTheme();
      const { toResetPassword } = useAuthenticator();
      return (
        <>
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Login to manage your subscription
          </Heading>
          <Text
            padding={`${tokens.space.xl} ${tokens.space.xl} 0 ${tokens.space.xl}`}
          >
            An account is created automatically when you subscribe to MDLCode.
            Click <Link onClick={toResetPassword}>Request new password</Link> to
            set your password for the first time.
          </Text>
        </>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            variation="link"
            style={{ marginBottom: "2rem" }}
          >
            Request New Password
          </Button>
        </View>
      );
    },
  },

  ResetPassword: {
    Header() {
      // const { tokens } = useTheme();
      return (
        <Heading padding={`0 0 0 0`} level={3}>
          Request new password
        </Heading>
      );
    },
  },
};

export const formFields = {
  signIn: {
    username: {
      placeholder: "Enter your email",
      label: "Email",
    },
  },
  resetPassword: {
    username: {
      placeholder: "Enter your email",
      label: "Email",
    },
  },
};

// https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#override-function-calls
export const authServices = {
  // async handleSignUp(formData) {
  //   console.log("--custom handleSignUp--", formData);
  //   let { username, password, attributes } = formData;
  //   // custom username
  //   username = username.toLowerCase();
  //   attributes.email = attributes.email.toLowerCase();
  //   return Auth.signUp({
  //     username,
  //     password,
  //     attributes,
  //     autoSignIn: {
  //       enabled: true,
  //     },
  //   });
  // },
  // async handleForgotPassword(formData) {
  //   console.log("--custom forgotPassword--", formData);
  //   return Auth.forgotPassword(formData);
  // },
  // async handleForgotPasswordSubmit(formData) {
  //   console.log("--custom forgotPasswordSubmit--", formData);
  //   return Auth.forgotPasswordSubmit(formData);
  // },
};

//const { tokens } = useTheme();
export const authTheme = (theme) => {
  return {
    name: "Auth Example Theme",
    tokens: {
      colors: {
        // background: {
        //   primary: {
        //     value: "red",
        //   },
        //   secondary: {
        //     value: "blue",
        //   },
        // },
        // font: {
        //   interactive: {
        //     value: "brown",
        //   },
        // },
        brand: {
          primary: {
            10: "transparent", // <- hover color for links
            80: theme.palette.secondary.main, // <- button and link color
            90: theme.palette.secondary.dark, // <- hover color for buttons
            100: theme.palette.primary.main, // <- input field outline
          },
        },
      },
      components: {
        button: {
          link: { _active: { backgroundColor: { value: "transparent" } } },
        },
        // tabs: {
        //   item: {
        //     _focus: {
        //       color: {
        //         value: "white",
        //       },
        //     },
        //     _hover: {
        //       color: {
        //         value: "yellow",
        //       },
        //     },
        //     _active: {
        //       color: {
        //         value: "orange",
        //       },
        //     },
        //   },
        // },
      },
    },
  };
};
