import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  "@global": {
    /**
     * Disable the focus outline, which is default on some browsers like
     * chrome when focusing elements
     */
    "*:focus": {
      outline: 0,
    },
    ".text-white": {
      color: theme.palette.common.white,
    },
    ".listItemLeftPadding": {
      paddingTop: `${theme.spacing(1.75)} !important`,
      paddingBottom: `${theme.spacing(1.75)} !important`,
      paddingLeft: `${theme.spacing(4)} !important`,
      [theme.breakpoints.down("md")]: {
        paddingLeft: `${theme.spacing(4)} !important`,
      },
      "@media (max-width:  420px)": {
        paddingLeft: `${theme.spacing(1)} !important`,
      },
    },
    ".container": {
      width: "100%",
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      marginRight: "auto",
      marginLeft: "auto",
      [theme.breakpoints.up("sm")]: {
        maxWidth: 540,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 720,
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 1170,
      },
    },
    ".row": {
      display: "flex",
      flexWrap: "wrap",
      marginRight: -theme.spacing(2),
      marginLeft: -theme.spacing(2),
    },
    ".container-fluid": {
      width: "100%",
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginRight: "auto",
      marginLeft: "auto",
      maxWidth: 1370,
    },
    ".lg-mg-top": {
      marginTop: `${theme.spacing(20)} !important`,
      [theme.breakpoints.down("lg")]: {
        marginTop: `${theme.spacing(18)} !important`,
      },
      [theme.breakpoints.down("md")]: {
        marginTop: `${theme.spacing(16)} !important`,
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: `${theme.spacing(14)} !important`,
      },
    },
    ".lg-mg-bottom": {
      marginBottom: `${theme.spacing(20)} !important`,
      [theme.breakpoints.down("lg")]: {
        marginBottom: `${theme.spacing(18)} !important`,
      },
      [theme.breakpoints.down("md")]: {
        marginBottom: `${theme.spacing(16)} !important`,
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: `${theme.spacing(14)} !important`,
      },
    },
    ".lg-p-top": {
      paddingTop: `${theme.spacing(20)} !important`,
      [theme.breakpoints.down("lg")]: {
        paddingTop: `${theme.spacing(18)} !important`,
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: `${theme.spacing(16)} !important`,
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: `${theme.spacing(14)} !important`,
      },
    },
    ".hiddenInput .MuiInputBase-root": {
      display: "none",
    },
    ".external-link-icon": {
      width: "11px",
      height: "11px",
      marginLeft: "4px",
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/%3E%3C/svg%3E")`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      display: "inline-block",
      marginRight: "3px",
    },
    ".docspage.docs-toc ul": {
      listStyleType: "none",
    },
    ".docspage.docs-toc ul li": {
      paddingBottom: "0.3rem",
      paddingTop: "0.3rem",
      display: "block",
    },
    ".docspage.docs-toc ul li ul": {
      paddingLeft: "0.3rem",
    },
    ".docspage.docs-toc ul li ul li": {
      paddingBottom: "0.1rem",
      paddingTop: "0.3rem",
    },
    ".docspage.docs-toc ul li:hover": {
      backgroundColor: theme.palette.grey[100],
    },
    ".docspage.docs-toc ul li a": {
      textDecoration: "none",
      color: theme.palette.grey[700],
    },
    ".docspage.docs-toc ul li.current a": {
      color: theme.palette.grey[900],
    },
    ".docspage img": {
      maxWidth: "100%",
    },
    ".tutorialspage img": {
      maxWidth: "100%",
    },
    ".tutorialspage pre div": {
      maxWidth: "95vw",
      overflowX: "auto",
    },
    ".docspage pre div": {
      maxWidth: "95vw",
      overflowX: "auto",
    },
    ".docspage table": {
      maxWidth: "95vw",
      overflowX: "auto",
      display: "flex",
    },
    ".docspage h2": {
      scrollMarginTop: "70px",
    },
    ".tutorialspage h2": {
      scrollMarginTop: "70px",
    },
    ".anchortarget": {
      scrollMarginTop: "70px",
    },
    a: {
      textDecoration: "none",
    },
    ".player-wrapper": {
      position: "relative",
      paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
    },
    ".react-player": {
      position: "absolute",
      top: 0,
      left: 0,
    },
  },
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
