import { initializePaddle } from "@paddle/paddle-js";
import axios from "axios";
const allCountries = require("../components/paddle_countries.json");

const initialState = {
  country: { objects: null, timeloaded: null, default: "US" },
  prices: {},
  userCountry: null,
  postcode: "",
};

/**
 * Reducer
 */
export const Pricing = (state = initialState, action) => {
  switch (action.type) {
    case "COUNTRY":
      return { ...state, country: action.value };
    case "PRICES":
      // Always adds to the prices object, never removes elements
      return { ...state, prices: { ...state.prices, ...action.value } };
    case "USERCOUNTRY":
      return { ...state, userCountry: action.value };
    case "POSTCODE":
      return { ...state, postcode: action.value };
    default:
      return state;
  }
};

const loadEntity = async (
  currentvalue,
  dispatch,
  type,
  getter,
  force,
  setLoading
) => {
  if (
    !(
      (
        force ||
        currentvalue?.objects === null ||
        Date.now() - parseInt(currentvalue?.timeloaded) > 24 * 60 * 60 * 1000
      ) // 24h timeout
    )
  ) {
    return;
  }

  // Load the entity
  const setEntity = (objects, timeloaded, def) =>
    dispatch({ type, value: { objects, timeloaded, default: def } });

  setEntity(
    currentvalue?.objects ?? currentvalue?.default,
    setLoading ? null : currentvalue?.timeloaded,
    currentvalue?.default
  );
  const response = await getter();
  setEntity(response, Date.now(), currentvalue?.default);
};

export const selectCountry = (state) => state.Pricing?.country?.objects;

export const selectCountryLoaded = (state) =>
  state.Pricing?.country?.timeloaded;

export function loadCountry(force = false, setLoading = false) {
  return async function loadCountryThunk(dispatch, getState) {
    await loadEntity(
      getState().Pricing.country,
      dispatch,
      "COUNTRY",
      () =>
        axios
          .get("https://ipapi.co/json/")
          // code: data.country_code, currency: data.currency, languages: data.languages
          .then((response) => response.data.country_code)
          .catch(() => {
            axios
              .get("https://api.country.is")
              .then((response) => response?.data?.country)
              .catch(() => "US");
          }),
      force,
      setLoading
    );
  };
}

export function countryRequiresPostcode(country) {
  const countryInfo = allCountries.data.find((c) => c.code === country);
  return countryInfo?.required_address_information?.includes("ZIP/postal code");
}

export function getPriceKey({ country, isCommercial, postcode }) {
  const requiresPostcode = countryRequiresPostcode(country);

  return `${country}-${isCommercial ? "comm" : "pers"}-${
    postcode && requiresPostcode ? postcode : ""
  }`;
}

export const selectPrices = (state) => state.Pricing?.prices;

export function loadPrices(country, postcode = "") {
  return async function loadPricesThunk(dispatch, getState) {
    let currentvalue = getState().Pricing.prices ?? {};
    let key = getPriceKey({ country, isCommercial: false, postcode });
    let key2 = getPriceKey({ country, isCommercial: true, postcode });
    if (
      !(
        !currentvalue[key] ||
        Date.now() - parseInt(currentvalue[key].timeloaded) >
          24 * 60 * 60 * 1000
      )
    ) {
      // console.log("...already loaded: ", currentvalue[key]);
      return;
    }
    if (currentvalue[key]?.loading) {
      // console.log("...already loading");
      return;
    }

    // Set as loading.
    await dispatch({
      type: "PRICES",
      value: { [key]: { loading: true }, [key2]: { loading: true } },
    });

    // Load the prices.
    const Paddle = await initialisePaddleMy();
    // console.log("Paddle initialized", Paddle);
    let v = await getPricesFromPaddle(Paddle, country, false, postcode);
    dispatch({ type: "PRICES", value: { [key]: v } });
    v = await getPricesFromPaddle(Paddle, country, true, postcode);
    dispatch({ type: "PRICES", value: { [key2]: v } });
  };
}

export const selectUserCountry = (state) =>
  state.Pricing?.userCountry ?? selectCountry(state) ?? "US";

export const setUserCountry = (country) => {
  return async function setUserCountryThunk(dispatch, getState) {
    dispatch({ type: "USERCOUNTRY", value: country });
  };
};

export const selectPostcode = (state) => state.Pricing?.postcode ?? "";

export const setPostcode = (postcode) => {
  return async function setPostcodeThunk(dispatch, getState) {
    dispatch({ type: "POSTCODE", value: postcode });
  };
};

export async function initialisePaddleMy() {
  const params = { token: process.env.REACT_APP_PADDLE_KEY };
  if (process.env.REACT_APP_PADDLE_ENVIRONMENT === "sandbox") {
    params.environment = "sandbox";
  }
  return await initializePaddle(params);
}

export async function getSinglePriceFromPaddle(
  priceId,
  customerId,
  addressId,
  quantity,
  discountId
) {
  const Paddle = await initialisePaddleMy();
  var request = {
    items: [{ quantity, priceId }],
    customerId,
    addressId,
  };
  if (discountId) {
    request.discountId = discountId;
  }

  try {
    const result = await Paddle.PricePreview(request);
    return {
      currencyCode: result.data.currencyCode,
      lineItems: result.data.details.lineItems,
      timeloaded: Date.now(),
    };
  } catch (error) {
    // TODO fill with some defaults? Warning about connection error?
    return null;
  }
}

async function getPricesFromPaddle(Paddle, country, isCommercial, postcode) {
  const priceId = isCommercial
    ? process.env.REACT_APP_PADDLE_COMM
    : process.env.REACT_APP_PADDLE_PERS;
  var request = {
    items: [{ quantity: 1, priceId }],
    address: {
      countryCode: country,
      postalCode: postcode,
    },
  };
  if (isCommercial) {
    for (let i = 2; i <= 10; i++) {
      request.items.push({ quantity: i, priceId });
    }
  }

  try {
    const result = await Paddle.PricePreview(request);
    return {
      currencyCode: result.data.currencyCode,
      lineItems: result.data.details.lineItems,
      timeloaded: Date.now(),
    };
  } catch (error) {
    // TODO fill with some defaults? Warning about connection error?
    return {};
  }
}
